/** @jsxImportSource theme-ui */

import React from "react"
import { ThemeUIProvider, jsx } from "theme-ui"
import dynamic from "next/dynamic"
import { ManagedUIContext, useUI } from "@components/ui/context"
import { ManagedTrackContext } from "@components/track/context"
import { Head, Navbar } from "@components/common"
import { useAcceptCookies } from "@lib/hooks/useAcceptCookies"
import { Button } from "theme-ui"
import { Sidebar } from "@components/ui"
import { CartSidebarView } from "@components/cart"
import { CommerceProvider } from "@lib/swell/storefront-data-hooks"
import swellConfig from "@config/swell"
import { builder, BuilderContent, Builder } from "@builder.io/react"
import themesMap from "@config/theme"
import "@builder.io/widgets"
import "react-spring-modal/styles.css"
import seoConfig from "@config/seo.json"
import NoSSR from "./NoSSR"
import Toast from "./Toast"
import { useRouter } from "next/router"

const FeatureBar = dynamic(() => import("@components/common/FeatureBar"), {
  ssr: false,
})

const defaultSidebarState: { openSidebar: boolean; setOpenSidebar: any } = {
  openSidebar: false,
  setOpenSidebar: null,
}
export const sidebarContext = React.createContext(defaultSidebarState)
interface LayoutProps {
  pageProps: any
  children: React.ReactNode
}
const Layout: React.FC<LayoutProps> = ({ children, pageProps }) => {
  const builderTheme = pageProps.theme
  const [openSidebar, setOpenSidebar] = React.useState(false)
  const sidebarState = { openSidebar, setOpenSidebar }
  return (
    <CommerceProvider {...swellConfig}>
      <BuilderContent content={builderTheme} modelName="theme">
        {(data, loading) => {
          if (loading && !builderTheme) {
            return "loading ..."
          }

          const siteSettings = data?.siteSettings
          const colorOverrides = data?.colorOverrides
          const siteSeoInfo = data?.siteInformation
          return (
            <ManagedUIContext key={data?.id} siteSettings={siteSettings}>
              <ManagedTrackContext>
                <Head seoInfo={siteSeoInfo || seoConfig} />
                <sidebarContext.Provider value={sidebarState}>
                  <InnerLayout themeName={data?.theme || "base"} colorOverrides={colorOverrides}>
                    {children}
                  </InnerLayout>
                </sidebarContext.Provider>
              </ManagedTrackContext>
            </ManagedUIContext>
          )
        }}
      </BuilderContent>
    </CommerceProvider>
  )
}

const InnerLayout: React.FC<{
  themeName: string
  colorOverrides?: {
    text?: string
    background?: string
    primary?: string
    secondary?: string
    muted?: string
  }
  children: React.ReactNode
}> = ({ themeName, children, colorOverrides }) => {
  const theme = {
    ...themesMap[themeName],
    colors: {
      ...themesMap[themeName].colors,
      ...colorOverrides,
    },
  }
  const { acceptedCookies, onAcceptCookies } = useAcceptCookies()
  const { openSidebar, setOpenSidebar } = React.useContext(sidebarContext)
  const router = useRouter()
  const pathname = router.pathname

  return (
    <ThemeUIProvider theme={theme}>
      {pathname.includes("configure") ? <></> : <Navbar />}
      <div
        sx={{
          margin: `0 auto`,
          maxWidth: 1920,
          minWidth: "60vw",
          minHeight: 800,
        }}
      >
        <main>{children}</main>
      </div>

      <CartSidebarView
        openSidebar={
          openSidebar || (builder.editingModel || Builder.previewingModel) === "cart-upsell-sidebar"
        }
        setOpenSidebar={setOpenSidebar}
      />

      <NoSSR>
        {/* <FeatureBar
          hide={Builder.isEditing ? true : acceptedCookies}
          action={<Button onClick={() => onAcceptCookies()}>Accept cookies</Button>}
        /> */}
      </NoSSR>
      <Toast />
    </ThemeUIProvider>
  )
}

export default Layout
